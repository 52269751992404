import { Type, type Static } from '@sinclair/typebox';
import {
	type InfiniteData,
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/vue-query';
import type { Products } from './products';
import { validatorFactory } from '@laam/lib/validator';
import { storeToRefs } from 'pinia';
import { useLoganStore } from '~/stores/logan';
import * as Sentry from '@sentry/vue';
import { useGlobalStore } from '~/stores/global';

const wishlistProductSchema = Type.Object({
	CreatedAt: Type.String(),
	id: Type.String(), // shopify product id
	featuredImage: Type.Object({
		altText: Type.String(),
		height: Type.Number(),
		id: Type.String(),
		src: Type.String(),
	}),
});

const wishlistProductDetailSchema = Type.Object({
	CreatedAt: Type.String(),
	id: Type.String(), // shopify product id
	title: Type.String(),
	vendor: Type.String(),
	tags: Type.Array(Type.String()),
	handle: Type.String(),
	availableForSale: Type.Boolean(),
	totalInventory: Type.Number(),
	compareAtPriceRange: Type.Object({
		maxVariantPrice: Type.Object({
			amount: Type.String(),
			currencyCode: Type.String(),
		}),
		minVariantPrice: Type.Object({
			amount: Type.String(),
			currencyCode: Type.String(),
		}),
	}),
	priceRange: Type.Object({
		maxVariantPrice: Type.Object({
			amount: Type.String(),
			currencyCode: Type.String(),
		}),
		minVariantPrice: Type.Object({
			amount: Type.String(),
			currencyCode: Type.String(),
		}),
	}),
	featuredImage: Type.Object({
		altText: Type.String(),
		height: Type.Number(),
		id: Type.String(),
		src: Type.String(),
	}),
	media: Type.Object({
		nodes: Type.Array(
			Type.Object({
				altText: Type.String(),
				height: Type.Number(),
				id: Type.String(),
				src: Type.String(),
				width: Type.Number(),
			}),
		),
	}),
	salePercentage: Type.Union([Type.Number(), Type.Null()]),
});

const wishlistSchema = Type.Object({
	id: Type.Number(),
	name: Type.String(),
	owner: Type.Number(),
	handle: Type.String(),
	deleted: Type.Boolean(),
	allow_gifting: Type.Boolean(),
	public: Type.Boolean(),
	created_at: Type.String(),
	hasMore: Type.Boolean(),
	products: Type.Mapped(Type.String(), () => wishlistProductSchema),
	totalProductsCount: Type.Number(),
	logan_id: Type.Number(),
});

const wishlistByHandleSchema = Type.Object({
	id: Type.Number(),
	name: Type.String(),
	owner: Type.Number(),
	handle: Type.String(),
	deleted: Type.Boolean(),
	allow_gifting: Type.Boolean(),
	public: Type.Boolean(),
	created_at: Type.String(),
	hasMore: Type.Boolean(),
	totalProductsCount: Type.Number(),
	products: Type.Mapped(Type.String(), () => wishlistProductDetailSchema),
});

export type Wishlist = Static<typeof wishlistSchema>;
export type WishlistDetail = Static<typeof wishlistByHandleSchema>;
export type WishlistProductDetail = Static<typeof wishlistProductDetailSchema>;
export type WishlistProduct = Static<typeof wishlistProductSchema>;
type WishlistResponse = {
	body: Wishlist[];
};
type DefaultWishlistResponse = {
	body: Wishlist;
};
type WishlistByHandleResponse = {
	body: WishlistDetail;
	status: number;
};

export interface AddToWishlistPayload {
	product_shopify_ids: number[];
	logan_id: number;
	wishlist_id: number;
}
interface EditWishlistPayload {
	logan_id: number;
	name: string;
	wishlist_id: number;
	allow_gifting: boolean;
	is_public: boolean;
	handle: string;
}

interface CreateWishlistPayload {
	name: string;
	allow_gifting: boolean;
	is_public: boolean;
	logan_id: number;
}

interface DeleteWishlistPayload {
	wishlist_id: number;
	logan_id: number;
}

export interface WishlistDetailTransformed extends WishlistDetail {
	products: Products['products'];
}

const userWishlistValidator = validatorFactory<WishlistResponse>(
	Type.Object({
		body: Type.Array(wishlistSchema),
	}),
);

const defaultWishlistValidator = validatorFactory<DefaultWishlistResponse>(
	Type.Object({
		body: wishlistSchema,
	}),
);

const wishlistByHandleValidator = validatorFactory<WishlistByHandleResponse>(
	Type.Object({
		body: wishlistByHandleSchema,
		status: Type.Number(),
	}),
);

export const createDefaultWishlist = async (loganId: number) => {
	const api = createApiInstance();
	const response = await api.post(
		'https://heimdall.laam.pk/wishlists/create-default-wishlist',
		{
			logan_id: loganId,
		},
	);
	if (response.status > 209) {
		throw new Error('Failed to create default wishlist');
	}
	try {
		const { body } = defaultWishlistValidator.verify(response.data);
		return body;
	} catch (error) {
		const err = new Error('Failed to verify default wishlist data');
		console.error('Failed to verify default wishlist data', error);
		Sentry.captureException(err, (scope) => {
			scope.setContext('errors', {
				error: err,
			});
			return scope;
		});
		throw err;
	}
};

export const getUserWishlists = async (loganId: number) => {
	const api = createApiInstance();
	const response = await api.get(
		'https://heimdall.laam.pk/wishlists/user-wishlists',
		{
			params: {
				logan_id: loganId,
			},
		},
	);
	if (response.status > 209) {
		throw new Error('Failed to fetch wishlist');
	}
	try {
		const { body } = userWishlistValidator.verify(response.data);
		if (body.length) {
			// find the default wishlist
			const favouriteList = body.find((wishlist) =>
				wishlist.name.includes('Favourites'),
			);
			if (favouriteList) {
				return body;
			}
			// create default wishlist and append to the lists
			const defaultWishlist = await createDefaultWishlist(loganId);
			return [...body, defaultWishlist];
		}
		// we need to create a default wishlist if the user has no wishlist
		const defaultWishlist = await createDefaultWishlist(loganId);
		return [defaultWishlist];
	} catch (error) {
		const err = new Error('Failed to verify user wishlist data');
		console.error('Failed to verify user wishlist data', error);
		Sentry.captureException(err, (scope) => {
			scope.setContext('errors', {
				error: err,
			});
			return scope;
		});
		throw err;
	}
};

const getWishlistProducts = async (
	wishlistHandle: string,
	page: number,
	limit?: number,
) => {
	const api = createApiInstance();
	const response = await api.get(
		'https://heimdall.laam.pk/wishlists/wishlist-by-handle',
		{
			params: {
				handle: wishlistHandle,
				page,
				...(limit && { items: limit }),
			},
		},
	);
	if (response.status > 209) {
		const error = new Error('Failed to fetch wishlist');
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', response.data);
			return scope;
		});
		throw error;
	}
	try {
		const { body } = wishlistByHandleValidator.verify(response.data);
		const products = Object.values(body.products) as WishlistProductDetail[];
		const transformedProducts: Products['products'] = products.map(
			(product) => ({
				title: '',
				shopify_id: parseInt(product.id.split('/').pop()!),
				media: [],
				category: '',
				drop_handle: product.title,
				brand: product.vendor,
				valhalla_score: 0,
				partner: '',
				status: '',
				description: '',
				external_product_id: '',
				compare_at_price: 0,
				price: 0,
				variants: [
					{
						title: '',
						shopify_id: 0,
						inventory_quantity: -1,
						weight_unit: '',
						weight: 0,
						sku: '',
						price: product.priceRange.minVariantPrice.amount,
						compare_at_price:
							product.compareAtPriceRange.minVariantPrice.amount,
						stock_status: product.availableForSale
							? 'in_stock'
							: 'out_of_stock',
					},
				],
				options: [],
				tags: product.tags,
				handle: product.handle,
				image: {
					alt: product.featuredImage.altText,
					src: product.featuredImage.src,
					height: product.featuredImage.height,
					shopify_id: 0,
					width: 0,
				},
				total_inventory_quantity: product.totalInventory,
			}),
		);
		body.products = transformedProducts;
		return body as WishlistDetailTransformed;
	} catch (error) {
		const err = new Error('Failed to verify wishlist data');
		console.error('Failed to verify wishlist data', error);
		Sentry.captureException(err, (scope) => {
			scope.setContext('errors', {
				error: err,
			});
			return scope;
		});
		throw err;
	}
};

export const removeFromWishlist = async (
	products: number[],
	loganId: number,
	wishlist: number,
) => {
	const api = createApiInstance();
	const response = await api.delete(
		'https://heimdall.laam.pk/wishlists/delete-products-from-wishlist',
		{
			data: {
				product_shopify_ids: products,
				logan_id: loganId,
				wishlist_id: wishlist,
			},
		},
	);
	if (response.status > 299) {
		const error = new Error('Failed to remove products from wishlist');
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', response.data);
			scope.setContext('payload', {
				products,
				loganId,
				wishlist,
			});
			return scope;
		});
		throw error;
	}
	return;
};

const editWishlist = async (payload: EditWishlistPayload) => {
	const api = createApiInstance();
	const response = await api.post(
		'https://heimdall.laam.pk/wishlists/edit-wishlist',
		payload,
	);
	if (response.status > 209) {
		const error = new Error('Failed to edit wishlist');
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', response.data);
			scope.setContext('payload', { ...payload });
			return scope;
		});
		throw error;
	}
	return;
};

const createWishlist = async (payload: CreateWishlistPayload) => {
	const api = createApiInstance();
	const response = await api.post(
		'https://heimdall.laam.pk/wishlists/create-wishlist',
		payload,
	);
	if (response.status !== 200) {
		const error = new Error('Failed to create wishlist');
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', response.data);
			scope.setContext('payload', { ...payload });
			return scope;
		});
		throw error;
	}
	return;
};

export const deleteWishlist = async (payload: DeleteWishlistPayload) => {
	const api = createApiInstance();
	const response = await api.delete(
		'https://heimdall.laam.pk/wishlists/delete-wishlist',
		{
			data: {
				wishlist_id: payload.wishlist_id,
				logan_id: payload.logan_id,
			},
		},
	);
	if (response.status > 299) {
		const error = new Error('Failed to delete wishlist');
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', response.data);
			scope.setContext('payload', { ...payload });
			return scope;
		});
		throw error;
	}
	return;
};

const addProductsToWishlist = async (payload: AddToWishlistPayload) => {
	const api = createApiInstance();
	const response = await api.post(
		'https://heimdall.laam.pk/wishlists/add-product-to-wishlist',
		payload,
	);
	if (response.status > 209) {
		const error = new Error('Failed to add products to wishlist');
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', response.data);
			scope.setContext('payload', { ...payload });
			return scope;
		});
		throw error;
	}
	return response.data;
};

export const useUserWishlists = (loganId: Ref<number>) => {
	const { storeType } = useGlobalStore();
	const isEnabled = computed(() => !!loganId.value && storeType !== 'OCTANE');
	const { data, isLoading, suspense } = useQuery<Wishlist[]>({
		queryKey: ['user-wishlists', loganId],
		queryFn: async () => getUserWishlists(loganId.value),
		enabled: isEnabled,
	});
	return { wishlistsResponse: data, areWishlistsLoading: isLoading, suspense };
};

export const useWishlistProductList = (
	handle: Ref<string | undefined>,
	initialData?: WishlistDetailTransformed,
	noLimit: boolean = false,
) => {
	const isEnabled = computed(() => !!handle.value);
	const {
		data,
		isLoading,
		suspense,
		error,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
	} = useInfiniteQuery<WishlistDetailTransformed>({
		queryKey: ['wishlist-detail', handle],
		queryFn: ({ pageParam = 1 }) => {
			return getWishlistProducts(
				handle.value!,
				pageParam as number,
				noLimit ? undefined : 24,
			);
		},

		getNextPageParam: (lastPage, _, lastPageParam) => {
			return lastPage.hasMore ? (lastPageParam as number) + 1 : null;
		},
		initialPageParam: 1,
		...(initialData && {
			initialData: {
				pages: [initialData],
				pageParams: [1],
			},
		}),
		refetchOnWindowFocus: false,
		enabled: isEnabled,
	});

	const { $eventClient } = useNuxtApp();
	watch(isFetchingNextPage, (isFetchingNextPage) => {
		if (isFetchingNextPage)
			$eventClient.sendEvent('pagination', {
				page_number: (data.value?.pages.length || 0) + 1,
				location: window.location.href,
			});
	});

	return {
		data,
		isLoading,
		suspense,
		error,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
	};
};

// for initial server side call on wishlist PLP
export const useWishlistByHandle = (handle: Ref<string>) => {
	const isEnabled = computed(() => !!handle.value);

	const { data, suspense, error } = useQuery({
		queryKey: ['wishlist-detail-server', handle],
		queryFn: async () => getWishlistProducts(handle.value, 1, 24),
		staleTime: Infinity,
		enabled: isEnabled,
	});
	return { data, suspense, error };
};

export const useRemoveFromWishlist = () => {
	interface RemoveFromWishlistParams {
		products: number[];
		wishlist: number;
		wishlistHandle: string;
	}
	const { loganId } = storeToRefs(useLoganStore());
	const queryClient = useQueryClient();
	const { mutate, isPending, isSuccess } = useMutation({
		mutationFn: ({ products, wishlist }: RemoveFromWishlistParams) =>
			removeFromWishlist(products, loganId.value, wishlist),
		onSuccess: async (_, variables) => {
			try {
				queryClient.setQueryData<InfiniteData<WishlistDetailTransformed>>(
					['wishlist-detail', variables.wishlistHandle],
					(old) => {
						if (!old) {
							return old;
						}
						const newData = {
							...old,
						};
						newData.pages = old!.pages.map((page) => ({
							...page,
							products: page.products.filter((product) => {
								return !variables.products.includes(product.shopify_id);
							}),
						}));
						return newData as InfiniteData<WishlistDetailTransformed>;
					},
				);
			} catch (error) {
				console.error('Error refetching wishlist', error);
			}
		},
	});

	return {
		removeFromWishlist: (params: RemoveFromWishlistParams) => mutate(params),
		isPending,
		isSuccess,
	};
};

export const useEditWishlist = () => {
	const queryClient = useQueryClient();
	const { mutate, isPending, isSuccess } = useMutation({
		mutationKey: ['edit-wishlist'],
		mutationFn: (payload: EditWishlistPayload) => editWishlist(payload),
		onSuccess: async (_, variables) => {
			try {
				queryClient.setQueryData<Wishlist[]>(
					['user-wishlists', variables.logan_id],
					(old) => {
						if (!old) {
							return old;
						}
						const newData = old.map((wishlist) => {
							if (wishlist.id === variables.wishlist_id) {
								// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
								const { logan_id, wishlist_id, handle, ...edited } = variables;
								return {
									...wishlist,
									...edited,
									public: edited.is_public,
								};
							}
							return wishlist;
						});
						return newData;
					},
				);
				queryClient.setQueryData<WishlistDetailTransformed>(
					['wishlist-detail-server', variables.handle],
					(old) => {
						if (!old) {
							return old;
						}
						// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
						const { logan_id, wishlist_id, handle, ...edited } = variables;
						return {
							...old,
							...edited,
						};
					},
				);
			} catch (error) {
				console.error('Error refetching wishlist', error);
			}
		},
	});

	return {
		editWishlist: (payload: EditWishlistPayload) => mutate(payload),
		isPending,
		isSuccess,
	};
};

export const useCreateWishlist = () => {
	const queryClient = useQueryClient();
	const { mutate, isPending, isSuccess } = useMutation({
		mutationKey: ['create-wishlist'],
		mutationFn: (payload: CreateWishlistPayload) => createWishlist(payload),
		onSuccess: async (_, variables) => {
			try {
				// wait for a second to let the server update the wishlist
				await new Promise((resolve) => setTimeout(resolve, 2000));
				// refetch user wishlists
				await queryClient.invalidateQueries({
					queryKey: ['user-wishlists', variables.logan_id],
				});
			} catch (error) {
				console.error('Error refetching wishlist', error);
			}
		},
	});

	return {
		createWishlist: (payload: CreateWishlistPayload) => mutate(payload),
		isPending,
		isSuccess,
	};
};

export const useDeleteWishlist = () => {
	const queryClient = useQueryClient();
	const { mutate, isPending, isSuccess } = useMutation({
		mutationKey: ['delete-wishlist'],
		mutationFn: (payload: DeleteWishlistPayload) => deleteWishlist(payload),
		onSuccess: async (_, variables) => {
			try {
				// wait for a second to let the server update the wishlist
				queryClient.setQueryData<Wishlist[]>(
					['user-wishlists', variables.logan_id],
					(old) => {
						if (!old) {
							return old;
						}
						return old.filter(
							(wishlist) => wishlist.id !== variables.wishlist_id,
						);
					},
				);
			} catch (error) {
				console.error('Error refetching wishlist', error);
			}
		},
	});

	return {
		deleteWishlist: (payload: DeleteWishlistPayload) => mutate(payload),
		isPending,
		isSuccess,
	};
};

export const useAddProductsToWishlist = () => {
	const queryClient = useQueryClient();
	const { mutate, isPending } = useMutation({
		mutationKey: ['add-products-to-wishlist'],
		mutationFn: (payload: AddToWishlistPayload) =>
			addProductsToWishlist(payload),
		onSuccess: async (_, variables) => {
			try {
				// wait for a second to let the server update the wishlist
				await new Promise((resolve) => setTimeout(resolve, 3000));
				// refetch current wishlist
				await queryClient.invalidateQueries({
					queryKey: ['user-wishlists', variables.logan_id],
				});
			} catch (error) {
				console.error('Error refetching wishlist', error);
			}
		},
	});
	return {
		addProductsToWishlist: (payload: AddToWishlistPayload) => mutate(payload),
		isPending,
	};
};
