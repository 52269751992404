import { defineStore } from 'pinia';
import {
	useAddProductsToWishlist,
	useUserWishlists,
	type Wishlist,
	type WishlistProduct,
} from '~/data/wishlist';
import { storeToRefs } from 'pinia';
import { useLoganStore } from './logan';

export const useWishlistStore = defineStore('wishlist', () => {
	const { loganId } = storeToRefs(useLoganStore());
	const { wishlistsResponse, areWishlistsLoading } = useUserWishlists(loganId);
	const { addProductsToWishlist } = useAddProductsToWishlist();
	const favouriteProductsQueue = ref<number[]>([]);

	const wishlists = computed(() => {
		if (!wishlistsResponse.value) {
			return null;
		}
		let favouriteList: Wishlist | null = null;
		const otherLists: Wishlist[] = [];
		for (const list of wishlistsResponse.value) {
			if (list.name !== 'Favourites') {
				otherLists.push(list);
			} else {
				favouriteList = list;
			}
		}
		if (!favouriteList) {
			return null;
		}
		return {
			favouriteList,
			otherLists,
		};
	});

	const favouritesMap = computed(() => {
		const set = new Set<string>();
		if (!wishlists.value?.favouriteList) {
			return set;
		}
		const {
			products,
		}: {
			products: {
				[key: string]: WishlistProduct;
			};
		} = wishlists.value.favouriteList;

		for (const [productId] of Object.entries(products)) {
			set.add(productId);
		}
		return set;
	});

	// handle delayed adding of products to wishlist
	watch(wishlists, (lists) => {
		if (lists && favouriteProductsQueue.value.length > 0) {
			const favListId = lists.favouriteList.id;
			addProductsToWishlist({
				logan_id: loganId.value,
				product_shopify_ids: favouriteProductsQueue.value,
				wishlist_id: favListId,
			});
			favouriteProductsQueue.value = [];
		}
	});

	return {
		wishlists,
		areWishlistsLoading,
		favouritesMap,
		favouriteProductsQueue,
	};
});

export const useWishlistTestStore = defineStore('wishlist', () => {
	const favouriteProductsQueue = ref<number[]>([]);

	const wishlists = computed(() => {
		return null;
	});

	const favouritesMap = computed(() => {
		const set = new Set<string>();
		return set;
	});

	// handle delayed adding of products to wishlist
	watch(wishlists, (lists) => {
		if (lists) {
			favouriteProductsQueue.value = [];
		}
	});

	return {
		wishlists,
		areWishlistsLoading: ref(false),
		favouritesMap,
		favouriteProductsQueue,
	};
});
